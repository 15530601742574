import React, { Component } from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { css } from "@emotion/core";
import Layout from "../components/layout.js";
import SEO from "../components/seo";
import Img from "gatsby-image";
import Helmet from "react-helmet";
//import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Navlinks from '../components/nav';
//import { DndProvider } from 'react-dnd';
//import HTML5Backend from 'react-dnd-html5-backend'

const productCategories=[];
const brandsArray=[];

const formArray = {
  categories: []
};

class MasterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      pName:  '',
      pDescription:  '',
      username: '',
      password: '', 
      pCategory0: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleChange = event => {
    const {name, value} = event.target
    
    this.setState({
      [name]: value
    })    
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
   
  handleSubmit = event => {
    event.preventDefault()
    const { pName, pDescription, username, password } = this.state
    alert(`Your registration detail: \n 
           Portfolio Name: ${pName} \n 
           Portfolio Description: ${pDescription} \n 
           Username: ${username} \n
           Password: ${password}`)
  }
  
  _next = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 2? 3: currentStep + 1
    this.setState({
      currentStep: currentStep,
    })
  }
    
  _prev = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1? 1: currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }

/*
* the functions for our button
*/
previousButton() {
  let currentStep = this.state.currentStep;
  if(currentStep !==1){
    return (
      <button 
        className="pFormButton" 
        type="button" onClick={this._prev}>
        Back
      </button>
    )
  }
  return null;
}

nextButton(){
  let currentStep = this.state.currentStep;
  if(currentStep <3){
    return (
      <button 
        className="pFormButton" 
        type="button" onClick={this._next}>
        Continue
      </button>        
    )
  }
  return null;
}

saveButton(){
  let currentStep = this.state.currentStep;
  if(currentStep == 3){
    return (
      <button 
        className="pFormButton" 
        type="button" onClick={this.handleSubmit}>
        Save
      </button>        
    )
  }
  return null;
}


  
  render() {    
    return (
      <React.Fragment>
      <form id="pForm" onSubmit={this.handleSubmit}>
      {/* 
        render the form steps and pass required props in
      */}
        <Step1 
          currentStep={this.state.currentStep} 
          handleChange={this.handleChange}
          pName={this.state.pName}
          pDescription={this.state.pDescription}
          pCategory0={this.state.pCategory0}
        />
        <Step2
          pName={this.state.pName} 
          currentStep={this.state.currentStep} 
          handleInputChange={this.handleInputChange}
          handleChange={this.handleChange}
          username={this.state.username}
          pCategory0={this.state.pCategory0}
        />
        <Step3
          pName={this.state.pName} 
          currentStep={this.state.currentStep} 
          handleChange={this.handleChange}
          pCategory0={this.state.pCategory0}
          password={this.state.password}
        />
        {this.previousButton()}
        {this.nextButton()}
        {this.saveButton()}
      </form>
      </React.Fragment>
    );
  }
}

function Step1(props) {
  if (props.currentStep !== 1) {
    return null
  }
  return(
    <div className="form-group">
      <h3>Create A New Portfolio</h3>
      <input
        className="form-control"
        id="pName"
        name="pName"
        type="text"
        placeholder="PORTFOLIO NAME"
        value={props.pName}
        onChange={props.handleChange}
      />
      <input
        className="form-control"
        id="pDescription"
        name="pDescription"
        type="text"
        placeholder="PORTFOLIO DESCRIPTION"
        value={props.pDescription}
        onChange={props.handleChange}
      />
    </div>
  );
}

function Step2(props) {
  if (props.currentStep !== 2) {
    return null
  }
  var pCat;
  return(
    <div className="form-group">
      <p class="portfolioHeader">Select product groups from which you want to include products for<br/><span>{props.pName}</span></p>
      {formArray.categories.map((category, index) => (
        <React.Fragment>
          {category.brands.length > 0 &&
            <div class="portfolioCheckbox">
              <input
                className="form-control"
                //id={category.pageID}
                id={'pCategory'+index}
                //name={category.pageID}
                name={'pCategory'+index}
                type="checkbox"
                checked={props.pCategory}
                onChange={props.handleInputChange}
              />
              <label for={category.pageID}>{category.title}</label>
            </div>
          }
          {category.brands.length == 0 &&
            <div class="portfolioCheckbox disabled">
              {category.title}
            </div>
          }
        </React.Fragment>
        ))}
    </div>
  );
}

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function Step3(props) {
  //const classes = useStyles();
  const theme = useTheme();
  const [productName, setProductName] = React.useState([]);

  const handleChange = event => {
    setProductName(event.target.value);
  };
  
  const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setProductName(value);
  };
  if (props.currentStep !== 3) {
    return null
  }
  return(
    <React.Fragment>
    <div className="form-group">
      <p class="portfolioHeader">Select products to add to<br/><span>{props.pName}</span></p>
      <div className="multi-selects">
        {formArray.categories.map(category => (
          <React.Fragment>
            {category.brands.map((brand,index) => (
              brand.products.length > 0 &&
              <FormControl>
              <InputLabel htmlFor={'select-multiple'+index}><span dangerouslySetInnerHTML={{ __html: brand.title}}/></InputLabel>
              <Select
                multiple="true"
                value={productName}
                onChange={handleChange}
                input={<Input id="select-multiple" />}
              >
              <React.Fragment>
                {brand.products.map(product => (
                  <React.Fragment>
                    <MenuItem key={product} value={product.pageID}>
                      <span dangerouslySetInnerHTML={{ __html: product.title}}/>
                    </MenuItem>
                  </React.Fragment>
                ))}
              </React.Fragment>
              </Select>
            </FormControl>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
    
    </React.Fragment>
  );
}

const stateArray = {
  items: []
};

var numOfPortfolios = 0;
var numOfActive = 0;
var numOfArchived=0;

function countPortfolios() {
  numOfPortfolios = numOfPortfolios+1;
};
function countArchived() {
  //numOfArchived = numOfArchived+1;
  //var activeList = document.getElementById("portfolioListActive").getElementsByTagName("li");
  var archivedList = document.getElementById("portfolioListArchived");
  archivedList = archivedList.getElementsByClassName("portfolioItem");
  console.log(archivedList);
  numOfArchived = archivedList.length;
  numOfActive = numOfPortfolios - numOfArchived;
  document.getElementById("archiveCount").innerHTML = numOfArchived;
  document.getElementById("activeCount").innerHTML = numOfActive;
};
  

function showPortfolio(e) {
  var pModal = document.getElementById(e.currentTarget.value);
  pModal.classList.add('active');
}
function closePModal(e) {
  var pModal = document.getElementById(e.currentTarget.value);
  pModal.classList.remove('active');
  
}

function portfolioFormOpen() {
  var pForm = document.getElementById("portfolioForm");
  pForm.classList.add('active');
}
function portfolioFormClose() {
  var pForm = document.getElementById("portfolioForm");
  pForm.classList.remove('active');
  document.getElementById('pForm').reset();
}
/*var numOfArchived= 0;
for(var i=0;i<stateArray.items.length;i++){
    if(stateArray.items[i].status == "archived")
    numOfArchived++;
}*/
function revealArchived() {
  var pList = document.getElementById("portfolioListContainer");
  var pHeader = document.getElementById("portfolioHeader");
  if (pList.classList.contains('activeP')) {
    pList.classList.remove('activeP');
    pList.classList.add('archivedP');
    pHeader.classList.remove('pActive');
    pHeader.classList.add('pArchived');
  } else {
    pList.classList.add('activeP');
    pList.classList.remove('archivedP');
    pHeader.classList.add('pActive');
    pHeader.classList.remove('pArchived');
  }
}
class DragList extends React.Component {
  componentDidMount() {
    countArchived()
  }
  state = stateArray;
  portfolioArchive(e) {
    var pIndex = e.currentTarget.getAttribute('data-item');
    e.preventDefault();
    //let status = this.state.items[pIndex].status;
    let items = this.state.items.filter(item => e);
    if (this.state.items[pIndex].status == "archived") {
      this.state.items[pIndex].status = "active";
      this.setState({ items });
      //console.log('True');

    } else {
      this.state.items[pIndex].status = "archived";
      //console.log('False');
      this.setState({ items });

    }
    var delayInMilliseconds = 1;
    setTimeout(function() {
      countArchived();
    }, delayInMilliseconds);
    
    return;
  }

  archiveConfirm(e) {
    var cPopup = e.currentTarget.getAttribute('data-item');
    var hPopupArchive = document.getElementsByClassName('archiveConfirmPopup');
    var hPopupDelete = document.getElementsByClassName('deleteConfirmPopup');
    var pConfirm = document.getElementById(cPopup);
    //var pHide = document.getElementById(hPopup);
    for (var i = 0; i < hPopupArchive.length; ++i) {
        var item = hPopupArchive[i];
        if (item.id !== cPopup) {
          item.classList.remove('active');
        }
    }
    for (var i = 0; i < hPopupDelete.length; ++i) {
      var itemd = hPopupDelete[i];
      if (itemd.id !== cPopup) {
        itemd.classList.remove('active');
      }
    }
    if (pConfirm.classList.contains('active')) {
      pConfirm.classList.remove('active');
    } else {
      pConfirm.classList.add('active');
    }
    
  }
  

  onDragStart = (e, index) => {
    this.draggedItem = this.state.items[index];
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, e.target.parentNode.offsetWidth - (e.target.parentNode.offsetWidth - e.clientX), 0);
  };

  onDragOver = index => {
    const draggedOverItem = this.state.items[index];
    //draggedOverItem.setStyles.marginBottom = '50px';
    //draggedOverItem.style.paddingBottom = 60;
    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let items = this.state.items.filter(item => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem);

    this.setState({ items });
  };

  onDragEnd = (e,index) => {
    this.draggedIdx = null;
  };

  render() {
    return (
      <div>
          <ul id="portfolioListActive" className="fCol12 portfolioList activeP">
            {this.state.items.map((item, idx) => (
              item.status == 'active' &&
              <React.Fragment>
              <li className="portfolioItem flexContainer activeP" key={item} onDragOver={() => this.onDragOver(idx)}>
                <button value={'pModal'+idx} class="portfolioInfo" onClick={showPortfolio}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                </button>
                <div className="portfolioButtons">
                  <button class="buttonArchive" data-item={'archiveConfirm'+idx} data-hide={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}><span class="icon-EyeSlash-rev1"></span></button>
                  <button class="buttonDelete" data-item={'deleteConfirm'+idx} data-hide={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}><span class="icon-TrashBin"></span></button>
                </div>
                <div
                  className="drag"
                  draggable
                  onDragStart={e => this.onDragStart(e, idx)}
                  onDragEnd={e => this.onDragEnd(e, idx)}
                >
                  <span className="dragIcon"></span>
                </div>
                <div id={'pModal'+idx} className="portfolioModal">
                <button className="close" value={'pModal'+idx} onClick={closePModal}/>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                <ul class="productList">
                  {item.products.length > 0 && item.products.map((product,index) =>(
                    <React.Fragment>
                      <a href={product.link} css={css`
                          border-left: 9px solid ${product.pColor} !important;
                        `}>
                        <li class="product">
                          <h3 dangerouslySetInnerHTML={{ __html: product.name}} />
                          <p>{product.excerpt}</p>
                        </li>
                      </a>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
              <div id={'archiveConfirm'+idx} className="archiveConfirmPopup">
                  <button class="closeArea" data-item={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}></button>
                  <div class="inner">
                    <h3>Are you sure you want to hide this portfolio?</h3>
                    <div className="buttons">
                      <button data-item={idx} onClick={e => this.portfolioArchive(e)}>Yes</button>
                      <button data-item={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>
              <div id={'deleteConfirm'+idx} className="deleteConfirmPopup">
                  <button class="closeArea" data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}></button>
                  <div class="inner">
                    <h3>Are you sure you want to delete this portfolio?</h3>
                    <span>Once a portfolio is deleted, it cannot be recovered.</span>
                    <div className="buttons">
                      <button data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}>Yes</button>
                      <button data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>
              </li>
              </React.Fragment>
            ))}
            </ul>
            <ul id="portfolioListArchived" className="fCol12 portfolioList archivedP">
            {this.state.items.map((item, idx) => (
              item.status == 'archived' &&
              <React.Fragment>
              <li className="portfolioItem flexContainer archivedP" key={item} onDragOver={() => this.onDragOver(idx)}>
                <button value={'pModal'+idx} class="portfolioInfo" onClick={showPortfolio}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                </button>
                <div className="portfolioButtons">
                  <button class="buttonArchive" data-item={'archiveConfirm'+idx} data-hide={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}><span class="icon-Eye"></span></button>
                  <button class="buttonDelete" data-item={'deleteConfirm'+idx} data-hide={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}><span class="icon-TrashBin"></span></button>
                </div>
                <div
                  className="drag"
                  draggable
                  onDragStart={e => this.onDragStart(e, idx)}
                  onDragEnd={e => this.onDragEnd(e, idx)}
                >
                  <span className="dragIcon"></span>
                </div>
                <div id={'pModal'+idx} className="portfolioModal">
                <button className="close" value={'pModal'+idx} onClick={closePModal}/>
                <h2>{item.title}</h2>
                <p>{item.description}</p>
                <ul class="productList">
                  {item.products.length > 0 && item.products.map((product,index) =>(
                    <React.Fragment>
                      <a href={product.link} css={css`
                          border-left: 9px solid ${product.pColor} !important;
                        `}>
                        <li class="product">
                          <h3 dangerouslySetInnerHTML={{ __html: product.name}} />
                          <p>{product.excerpt}</p>
                        </li>
                      </a>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
              <div id={'archiveConfirm'+idx} className="archiveConfirmPopup">
                  <button class="closeArea" data-item={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}></button>
                  <div class="inner">
                    <h3>Are you sure you want to activate this portfolio?</h3>
                    <div className="buttons">
                      <button class="buttonArchive" data-item={idx} onClick={e => this.portfolioArchive(e)}>Yes</button>
                      <button data-item={'archiveConfirm'+idx} onClick={e => this.archiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>
              <div id={'deleteConfirm'+idx} className="deleteConfirmPopup">
                <button class="closeArea" data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}></button>
                  <div class="inner">
                    <h3>Are you sure you want to delete this portfolio?</h3>
                    <span>Once a portfolio is deleted, it cannot be recovered.</span>
                    <div className="buttons">
                      <button data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}>Yes</button>
                      <button data-item={'deleteConfirm'+idx} onClick={e => this.archiveConfirm(e)}>No</button>
                    </div>
                  </div>
              </div>
              </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
    );
  }
}





const PortfolioTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Helmet>
      <body className="portfolio" />
    </Helmet>
    
    <header className="mainHead flexContainer faCenter fjCenter portfolioHead" css={css`
        
    `}>      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/#`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span> Home
          </Link>
          
          {/* Nav Side */}
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
          <span className="icons icon-search"></span>
          <span className="icons icon-notifications-bell-open"></span>
             
          <Navlinks />
        </div>
    </header>
    <div id="tabsContainer" className="tabsContainer portfolio" css={css`margin-top:5vh;`}>
    <section id="portfolio-content" className="flexContainer active secondaryTab">
    <div className="hide">{numOfPortfolios = 0}{stateArray.items.length=0}</div>
    {data.wp.user.User_Custom_Data_Points.userPortfolios.map((portfolio, index) =>(
        <React.Fragment>
          <div className="hide">
          {stateArray.items.push({"title": portfolio.portfolioName,"description": portfolio.portfolioDescription,"products": [], "status": portfolio.portfolioStatus})}
          {countPortfolios()}
          {portfolio.portfolioStatus == "archived"}
          {stateArray.items[index].products.length=0}
            {portfolio.portfolioProducts.map(product =>(
              <React.Fragment>
                <div>
                  {stateArray.items[index].products.push({"name": product.Product_Page_Fields.productName,"excerpt": product.Product_Page_Fields.productExcerpt,"link": product.parent.parent.parent.slug+'/'+product.parent.parent.slug+'/'+product.parent.slug+"/products/"+product.slug, "pColor": product.Product_Page_Fields.productColor})}
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      ))}
      <div id="portfolioHeader" className="fCol12 pActive">
        
        <div class="flexContainer fCol12">
          <div class="portfolioInfo">
            <h1 id="activePortTitle">{data.wordpressPage.acf.port_title} <span>[ &nbsp;{numOfPortfolios}/20&nbsp; ]</span></h1>
            <h1 id="archivedPortTitle">{data.wordpressPage.acf.port_title_archived} <span>[ &nbsp;{numOfPortfolios}/20&nbsp; ]</span></h1>
            <p id="activePortContent">
              {data.wordpressPage.acf.port_description}<br/>
              {numOfPortfolios >= 20 && <strong>{data.wordpressPage.acf.port_oci}</strong>}
            </p>
            <p id="archivedPortContent">
              {data.wordpressPage.acf.port_description_archived}<br/>
              {numOfPortfolios >= 20 && <strong>{data.wordpressPage.acf.port_oci}</strong>}
            </p>
          </div>
          <div class="portfolioHeaderButtons flexContainer">
            {numOfPortfolios >= 20 && <button className="disabled createNewPortfolio"><span class="icon-CreateNewPortfolio-rev1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>Create a New Portfolio</button>}
            {numOfPortfolios < 20 && <button className="createNewPortfolio" onClick={portfolioFormOpen}><span class="icon-CreateNewPortfolio-rev1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span></span>Create a New Portfolio</button>}
            <button onClick={revealArchived} className="archiveButton"><span class="icon-FolderEye"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span id="archiveCount" className="archiveCount">{numOfArchived}</span></span></button>
            <button onClick={revealArchived} className="activePortfolioButton archived"><span class="icon-CreateNewPortfolio"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span id="activeCount" className="activeCount">{numOfActive}</span></span>Back to Visible Portfolios</button>
          </div>
        </div>
      </div>
      <div id="portfolioListContainer" className="fCol12 activeP">
        <DragList/>
      </div>
      <div className="fCol12">
        <div>
          <div className="hide">
            {productCategories.length = 0}
            {brandsArray.length = 0}
          </div>
          <div className="hide">
          {data.allWordpressPage.edges.map(product =>(
            <React.Fragment>
              {!productCategories.includes(product.node.parent_element.parent_element.acf.category_name) &&
                productCategories.push(product.node.parent_element.parent_element.acf.category_name)
              }
            </React.Fragment>
            //productCategories.push({"catID":product.node.wordpress_id,"catTitle":product.node.wordpress_id})
          ))}
          </div>
          {/*productCategories.map(cat=>(cat))/*/}
        </div>
      </div>
      <div className="hide">{formArray.categories.length=0}</div>
      <div className="hide">
      {data.wp.pages.edges.map((top) =>(
        <React.Fragment>
          {top.node.childPages.edges.map((category,iC) =>(
            <React.Fragment>
              <div className="hide">{formArray.categories.push({"title": category.node.title,"pageID": category.node.pageId,"brands": [] })}</div>
              {category.node.childPages.edges.map((brand, iB) =>(
                <React.Fragment>
                  <div className="hide">{formArray.categories[iC].brands.push({"title": brand.node.title,"pageID": brand.node.pageId,"products": [] })}</div>
                  {brand.node.childPages.edges.map((product, iP) =>(
                    <React.Fragment>
                      <div className="hide">{formArray.categories[iC].brands[iB].products.push({"title": product.node.Product_Page_Fields.productName,"pageID": product.node.pageId})}</div>
                      {product.node.Product_Page_Fields.productName}<br/>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      </div>
      <div id="portfolioForm" className="portfolioForm">
        <button className="close" onClick={portfolioFormClose}/>
        <MasterForm/>
      </div>
    </section>
    </div>
  </Layout>
)
  
export default PortfolioTemplate
  
export const query = graphql`
  query($id: Int!) {
    wp {
      pages {
        edges {
          node {
            childPages(where: {orderby: {field: TITLE, order: ASC}}) {
              edges {
                node {
                  title
                  pageId
                  childPages {
                    edges {
                      node {
                        pageId
                        childPages {
                          edges {
                            node {
                              pageId
                              Product_Page_Fields {
                                productName
                              }
                            }
                          }
                        }
                        title
                      }
                    }
                  }
                }
              }
            }
            pageId
            title
          }
        }
      }
      user(id: "dXNlcjoz") {
        User_Custom_Data_Points {
          fieldGroupName
          userPortfolios {
            portfolioDescription
            portfolioName
            portfolioStatus
            portfolioProducts {
              ... on WP_Page {
                id
                Product_Page_Fields {
                  productName
                  productExcerpt
                  productColor
                  
                }
                pageId
                slug
                parent {
                  ... on WP_Page {
                    slug
                    parent {
                      ... on WP_Page {
                        slug
                        parent {
                          ... on WP_Page {
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
        menuLinks {
          link
          name
        }
      }
    }
    allWordpressPage(filter: {acf: {type_of_page: {eq: "product"}}}, sort: {fields: acf___product_name}) {
      edges {
        node {
          id
          acf {
            product_name
          }
          parent_element {
            parent_element {
              acf {
                category_name
              }
              wordpress_id
            }
            acf {
              brand_name
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      slug
      acf {
        port_title
        port_title_archived
        port_description
        port_description_archived
        port_oci
      }   
    }
  }
`